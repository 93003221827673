const gtm = () => {
  return process.env.NEXT_PUBLIC_GTM_KEY;
};
const env = () => {
  return process.env.NEXT_PUBLIC_ENV;
};
const mixPanel = () => {
  return process.env.NEXT_PUBLIC_MIXPANEL_DEBUG;
};
export { gtm, env, mixPanel };
