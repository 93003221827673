const initState = {
  authPhoneNumber: "",
  authOtpRequested: false,
  authOtpRequestBody: null,
  verified: false,
  authToken: "",
  profiles: [
    {
      id: 0,
      name: "",
      first_name: "",
      last_name: "",
      user_id: 0,
      profile_type: "",
      next_live_class: {
        id: "",
        name: "",
        start_time: null,
        end_time: null,
        link: null,
        participant_type: "",
        is_reschedulable: false,
        session_no: 0,
        leadsquared_class_type: "",
      },
    },
  ],

  teacherProfiles: [],
  userDetails: {},
  hasVerifiedNumber: false,
  hasVerifiedEmail: false,
  signupModalOpen: false,
  loginModalOpen: false,
  guestModalOpen: false,
  meetingDetails: null,
  tempCountryCode: "",
  tempPhoneNumber: "",
  tempFullName: "",
  tempChildName: "",
  tempEmail: "",
  triggeredBy: "",
  freeLoginModalOpen: false,
  openCheckoutLoginModal: false,
  verifyOTPClick: false,
  freeLogin: "",
  authSourcePage: "",
  authDestinationPage: "",
  validCountrycodes: [],
  allCourses: [],
};
const authReducers = (state = initState, action) => {
  switch (action.type) {
    case "SEND_OTP":
      return {
        ...state,
        authOtpRequested: true,
        authOtpRequestBody: action.payload,
      };
    case "CHANGE_NUMBER":
      return { ...state, authOtpRequested: false };
    case "SET_AUTH_SOURCE_PAGE":
      return { ...state, authSourcePage: action.payload };
    case "SET_AUTH_DESTINATION_PAGE":
      return { ...state, authDestinationPage: action.payload };
    case "SET_AUTH_FROM_SPARK_NATIVE":
      return {
        ...state,
        authToken: action.payload.auth_token,
        userDetails: action.payload.user_details,
        profiles: action.payload.profiles,
        hasVerifiedNumber: true,
        verified: true,
      };
    case "SET_ALL_FILTERED_COURSES":
      return { ...state, allCourses: [...action.payload] };
    case "LOGIN_WITH_OTP":
      return {
        ...state,
        verified: true,
        authOtpRequested: false,
        hasVerifiedNumber: action.payload.hasVerifiedNumber,
        hasVerifiedEmail: action.payload.hasVerifiedEmail,
        profiles: action.payload.profiles,
        teacherProfiles: action.payload.teacherProfiles,
        userDetails: action.payload.userDetails,
        referralDetails: action.payload.referralDetails,
        authToken: action.payload.authToken,
        freeLoginModalOpen: false,
        loginModalOpen: false,
        signupModalOpen: false,
        authSourcePage: "",
        authDestinationPage: "",
      };

    case "CHECKOUT_LOGIN_MODAL":
      return { ...state, openCheckoutLoginModal: action.payload };

    case "SAVE_AUTH_TOKEN":
      return { ...state, authToken: action.payload };
    case "SIGNUP_MODAL_OPEN":
      return {
        ...state,
        signupModalOpen: !state.signupModalOpen,
      };
    case "LOGIN_MODAL_OPEN":
      return {
        ...state,
        loginModalOpen: !state.loginModalOpen,
      };
    case "GUEST_MODAL_OPEN":
      return { ...state, guestModalOpen: !state.guestModalOpen };
    case "SET_MEETING_DETAILS":
      return { ...state, meetingDetails: action.payload };
    case "TEMP_PHONE_NUMBER":
      return {
        ...state,
        tempCountryCode: action.payload.countryCode,
        tempPhoneNumber: action.payload.phoneNumber,
      };
    case "TEMP_FULL_NAME":
      return {
        ...state,
        tempFullName: action.payload,
      };
    case "TEMP_EMAIL":
      return {
        ...state,
        tempEmail: action.payload,
      };
    case "SET_AUTH_TRIGGERED_BY":
      return {
        ...state,
        triggeredBy: action.payload,
      };
    case "SET_PROFILES":
      return {
        ...state,
        profiles: [...action.payload],
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        authToken: "",
        profiles: [],
        userDetails: {},
        authSourcePage: "",
        authDestinationPage: "",
        openCheckoutLoginModal: false,
      };
    case "VERIFY_OTP_CLICK":
      return {
        ...state,
        verifyOTPClick: action.payload,
      };
    case "FREE_LOGIN_MODAL_OPEN":
      return { ...state, freeLoginModalOpen: !state.freeLoginModalOpen };
    case "FREE_LOGIN":
      return {
        ...state,
        verified: true,
        authOtpRequested: false,
        hasVerifiedNumber: action.payload.hasVerifiedNumber,
        hasVerifiedEmail: action.payload.hasVerifiedEmail,
        profiles: action.payload.profiles,
        teacherProfiles: action.payload.teacherProfiles,
        userDetails: action.payload.userDetails,
        authToken: action.payload.authToken,
        freeLoginModalOpen: action.payload.freeLoginModalOpen,
      };

    case "SET_VALID_COUNTRY_CODE":
      return {
        ...state,
        validCountrycodes: action.payload,
      };
    case "SET_NEW_ALL_COURSES":
      return { ...state, allCourses: [...action.payload] };
    default:
      return state;
  }
};
export { authReducers };
