const initState = {
  cartDrawer: false,
  cartTooltip: false,
  cartTooltipData: "",
  cart: [],
  paid: [],
  promoCode: "",
  promoCodeResponse: {},
  promoCodeUiStates: {
    promoInputClassName: "cart-preview__promo-code--input",
    promoTooltipClassName: "cart-preview__promo-code--validation-tooltip",
    promoTooltipText: "",
  },
  directBuyDetails: {
    courseDisplayName: "",
    urlCourseName: "",
    isDirectBuy: false,
    canBuyWithoutBatchSelection: true,
    hasAvailableBatches: false,
    directBuyCoupon: "",
    nearestBatchStartDate: 0,
  },
  buyCourseId: "",
  address: {},
  amountPaid: "",
  preDiscountPrice: "",
  batchSelectionModalOpen: false,
  selectedBatchId: 0,
};
const checkoutReducers = (state = initState, action) => {
  let currentCart;
  switch (action.type) {
    // ! Cart related reducers
    case "CART_DRAWER_OPEN":
      return { ...state, cartDrawer: !state.cartDrawer };
    case "CART_TOOLTIP_OPEN":
      return {
        ...state,
        cartTooltip: true,
        cartTooltipData: action.payload,
      };
    case "CART_TOOLTIP_CLOSE":
      return {
        ...state,
        cartTooltip: false,
      };
    case "ADD_TO_CART":
      return {
        ...state,
        cartTooltip: true,
        cart: [...state.cart, action.payload],
      };
    case "ADD_QTY_TO_CART":
      currentCart = state.cart;
      currentCart.forEach((course) => {
        if (course.courseId === action.payload) {
          course.qty += 1;
        }
      });
      return window.location.pathname === "/checkout/"
        ? { ...state, cartTooltip: false, cart: [...currentCart] }
        : { ...state, cartTooltip: true, cart: [...currentCart] };
    case "REMOVE_QTY_FROM_CART":
      currentCart = state.cart;
      let indexOfZero;
      currentCart.forEach((course, index) => {
        if (course.courseId === action.payload) {
          course.qty -= 1;
          if (course.qty === 0) {
            indexOfZero = index;
          }
        }
      });
      if (indexOfZero !== undefined) {
        currentCart.splice(indexOfZero, 1);
      }
      return window.location.pathname === "/checkout/"
        ? { ...state, cartTooltip: false, cart: [...currentCart] }
        : { ...state, cartTooltip: true, cart: [...currentCart] };
    case "PAYMENT_SUCCESSFUL":
      return { ...state, paid: [...state.cart], cart: [] };
    case "SET_PROMO_CODE":
      return { ...state, promoCode: action.payload };
    case "SET_PROMO_CODE_RESPONSE":
      return { ...state, promoCodeResponse: action.payload };
    case "SET_PROMO_CODE_UI_STATES":
      return {
        ...state,
        promoCodeUiStates: { ...state.promoCodeUiStates, ...action.payload },
      };
    case "SET_AMOUNT_PAID":
      return { ...state, amountPaid: action.payload };
    case "SET_PRE_DISCOUNT_AMOUNT":
      return { ...state, preDiscountPrice: action.payload };

    case "SET_BUY_NOW_COURSE_ID":
      return { ...state, buyCourseId: action.payload };

    case "SET_ADDRESS":
      return { ...state, address: action.payload };
    case "SET_DIRECT_BUY_DETAILS":
      return {
        ...state,
        directBuyDetails: { ...state.directBuyDetails, ...action.payload },
      };
    case "SET_BATCH_SELECTION_MODAL_OPEN":
      return {
        ...state,
        batchSelectionModalOpen: !state.batchSelectionModalOpen,
      };
    case "SET_SELECTED_BATCH_ID":
      return { ...state, selectedBatchId: action.payload };
    default:
      return state;
  }
};
export { checkoutReducers };
