const initState = {
  sttToken: {},
  refetchToken: {},
  age: 0,
  isAgeModalOpen: false,
  audio64: "",
  audio64_1: "",
  audio64_2: "",
  pauseTranscription: "",
  originalTranscription_0: "",
  originalTranscription_1: "",
  originalTranscription_2: "",
  finalTranscription_0: "",
  finalTranscription_1: "",
  finalTranscription_2: "",
  storyTitle: "",
  stage: 0,
  attemptsId: 0,
  gamesResponse: {},
};
const recordingReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_AUDIO_FILE":
      return { ...state, audio64: action.payload };

    case "SET_AUDIO_FILE_1":
      return { ...state, audio64_1: action.payload };

    case "SET_AUDIO_FILE_2":
      return { ...state, audio64_2: action.payload };

    case "SET_FINAL_TRANSCRIPTION_0":
      return { ...state, finalTranscription_0: action.payload };

    case "SET_FINAL_TRANSCRIPTION_1":
      return { ...state, finalTranscription_1: action.payload };

    case "SET_FINAL_TRANSCRIPTION_2":
      return { ...state, finalTranscription_2: action.payload };

    case "SET_PAUSE_TRANSCRIPTION":
      return { ...state, pauseTranscription: action.payload };

    case "SET_ORIGNAL_TRANSCRIPTION_0":
      return { ...state, originalTranscription_0: action.payload };

    case "SET_ORIGNAL_TRANSCRIPTION_1":
      return { ...state, originalTranscription_1: action.payload };

    case "SET_ORIGNAL_TRANSCRIPTION_2":
      return { ...state, originalTranscription_2: action.payload };

    case "SET_STAGE":
      return { ...state, stage: action.payload };
    case "SET_STORY_TITLE":
      return { ...state, storyTitle: action.payload };

    case "SET_GAME_ATTEMPT_ID":
      return { ...state, attemptsId: action.payload };

    case "SET_AGE":
      return { ...state, age: action.payload };

    case "SET_AGE_MODAL":
      return { ...state, isAgeModalOpen: action.payload };

    case "SET_GAME_RESPONSE":
      return { ...state, gamesResponse: { ...action.payload } };

    case "SET_STT_TOKEN":
      return { ...state, sttToken: { ...action.payload } };

    case "SET_REFETCH_TOKEN":
      return { ...state, refetchToken: { ...action.payload } };

    default:
      return state;
  }
};
export { recordingReducers };
