const initState = {
  allCourses: [],
  activeCourseOnCoursePage: "",
  scrollToCourseCategory: "",
  isAgeSelectorOpen: false,
  urgencyText: "",
  ageRange: "",
};
const coursesReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_ALL_COURSES":
      return { ...state, allCourses: [...action.payload] };
    case "ACTIVE_COURSE_ON_COURSE_PAGE":
      return { ...state, activeCourseOnCoursePage: action.payload };
    case "SCROLL_TO_COURSE_CATEGORY":
      return { ...state, scrollToCourseCategory: action.payload };
    case "SET_IS_AGE_SELECTOR_OPEN":
      return { ...state, isAgeSelectorOpen: action.payload };
    case "SET_URGENCY_TEXT":
      return { ...state, urgencyText: action.payload };
    case "SET_AGE_RANGE":
      return { ...state, ageRange: action.payload };
    default:
      return state;
  }
};
export { coursesReducers };
