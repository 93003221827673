const initState = {
  isFirstLandingOnBookATrial: true,
};

const bookingSessionReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_IS_FIRST_LANDING_ON_BOOK_A_TRIAL":
      return {
        ...state,
        isFirstLandingOnBookATrial: action.payload,
      };
    default:
      return state;
  }
};

export { bookingSessionReducers };
