const initState = {
  nextClass: {},
  zoom_active_state: "JOIN_ON_LAPTOP",
  zoom_active_state_percentage: 10,
  timeLeft: {},
  openSnackBar: false,
  ipaddress: null,
  PERCENTAGE_LEFT: {
    INITIAL_LOAD: 0,
    JOIN_ON_LAPTOP: 25,
    ZOOM_ON_LAPTOP: 50,
    CHECK_YOUR_MAIL: 75,
    HOW_FUN_CLASS: 100,
    ZOOM_ON_MOBILE: 50,
    INSTALL_ZOOM_MOBILE: 75,
    CLASS_STARTS_IN: 100,
    CLASS_HAS_STARTED: 100,
    TROUBLE_JOINING_CLASS: 100,
  },
};
const liveClassReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_NEXT_CLASS_DETAILS":
      return { ...state, nextClass: action.payload };

    case "SET_ZOOM_MODAL_ACTIVE_STATE":
      return {
        ...state,
        zoom_active_state: action.payload,
      };
    case "SET_ZOOM_MODAL_ACTIVE_STATE_PERCENTAGE":
      return {
        ...state,
        zoom_active_state_percentage: action.payload,
      };
    case "SET_TIME_LEFT":
      return {
        ...state,
        timeLeft: action.payload,
      };
    case "SET_SNACKBAR_STATE":
      return {
        ...state,
        openSnackBar: action.payload,
      };
    case "SET_IP_ADDRESS":
      return {
        ...state,
        ipaddress: action.payload,
      };

    default:
      return state;
  }
};
export { liveClassReducers };
