const initState = {
  gameTokens: {
    current: { token: "", timestamp: 0 },
    next: { token: "", timestamp: 0 },
  },
  gameTokenCurrent: { token: "", timestamp: 0 },
  gameTokenNext: { token: "", timestamp: 0 },
  audioFiles: { stage_1: "", stage_2: "", stage_3: "" },
  originalTranscripts: { stage_1: "", stage_2: "", stage_3: "" },
  finalTranscripts: { stage_1: "", stage_2: "", stage_3: "" },
  gameDetails: {},
  difficultyLevel: 1,
  gameTempChildAge: 0,
  currentStage: 1,
  gameAttemptId: 0,
  storyTitle: "",
  isPaused: false,
  currentStateTime: 0,
  initialRecordingOnStage: false,
  autoSubmitStage_1: false,
  fteustep: 4,
  gameresults: null,
  showFtue: true,
};
const gamesReducer = (state = initState, action) => {
  switch (action.type) {
    // ! Reset Game
    case "RESET_GAME_STATES":
      return {
        ...state,
        gameTokens: {
          current: { token: "", timestamp: 0 },
          next: { token: "", timestamp: 0 },
        },
        gameTokenCurrent: { token: "", timestamp: 0 },
        gameTokenNext: { token: "", timestamp: 0 },
        audioFiles: { stage_1: "", stage_2: "", stage_3: "" },
        originalTranscripts: { stage_1: "", stage_2: "", stage_3: "" },
        finalTranscripts: { stage_1: "", stage_2: "", stage_3: "" },
        gameDetails: {},
        difficultyLevel: 1,
        currentStage: 1,
        gameAttemptId: 0,
        storyTitle: "",
        currentStateTime: 0,
        fteustep: 0,
        initialRecordingOnStage: false,
        autoSubmitStage_1: false,
        isPaused: false,
        gameresults: null,
      };
    // ! game tokens
    case "SET_RESTART":
      return {
        ...state,
        originalTranscripts: { stage_1: "", stage_2: "", stage_3: "" },
        finalTranscripts: { stage_1: "", stage_2: "", stage_3: "" },
        currentStage: 1,
        gameresults: null,
      };
    case "SET_GAME_TOKEN_CURRENT":
      return {
        ...state,
        gameTokenCurrent: { ...state.gameTokenCurrent, ...action.payload },
      };
    // ! state timer
    case "SET_TIME":
      return {
        ...state,
        currentStateTime: action.payload,
      };
    // ! state fteu
    case "SET_FTEU_STEP":
      return {
        ...state,
        fteustep: action.payload,
      };

    // ! game tokens
    case "SET_GAME_TOKEN_NEXT":
      return {
        ...state,
        gameTokenNext: { ...state.gameTokenNext, ...action.payload },
      };
    // ! game tokens
    case "SET_GAME_TOKENS":
      return {
        ...state,
        gameTokens: { ...state.gameTokens, ...action.payload },
      };
    // ! Audio files
    case "SET_AUDIO_FILES":
      return {
        ...state,
        audioFiles: { ...state.audioFiles, ...action.payload },
      };
    // ! transcripts
    case "SET_ORIGINAL_TRANSCRIPTS":
      return {
        ...state,
        originalTranscripts: {
          ...state.originalTranscripts,
          ...action.payload,
        },
      };
    case "SET_FINAL_TRANSCRIPTS":
      return {
        ...state,
        finalTranscripts: {
          ...state.finalTranscripts,
          ...action.payload,
        },
      };
    // ! Game details
    case "SET_GAME_DETAILS":
      return {
        ...state,
        gameDetails: { ...action.payload },
      };
    // ! Game child age
    case "SET_GAME_CHILD_AGE":
      return {
        ...state,
        gameTempChildAge: action.payload,
      };
    // ! Game attempt id
    case "SET_GAME_ATTEMPT_ID":
      return {
        ...state,
        gameAttemptId: action.payload,
      };
    // ! Game story title
    case "SET_GAME_STORY_TITLE":
      return {
        ...state,
        storyTitle: action.payload,
      };
    // ! Game story title
    case "SET_DIFFICULTY_LEVEL":
      return {
        ...state,
        difficultyLevel: action.payload,
      };
    // ! Game currentStage
    case "SET_GAME_CURRENT_STAGE":
      return {
        ...state,
        currentStage: action.payload,
      };
    // ! Game initialRecording
    case "SET_INITIAL_RECORDING_ON_STAGE":
      return {
        ...state,
        initialRecordingOnStage: action.payload,
      };
    // ! Game initialRecording
    case "SET_AUTOSUBMIT_STAGE_1":
      return {
        ...state,
        autoSubmitStage_1: action.payload,
      };
    // ! Game pauseState
    case "SET_PAUSE_STATE":
      return {
        ...state,
        isPaused: action.payload,
      };

    case "SET_RESULTS":
      return {
        ...state,
        gameresults: action.payload,
      };

    case "SET_SHOW_FTUE":
      return {
        ...state,
        showFtue: action.payload,
      };

    default:
      return state;
  }
};
export { gamesReducer };
