const initState = {
  utmObject: {},
  currency: "INR",
  referrerDetails: {
    referrer_code: null,
    referrer_found: false,
    referrer_user_details: {},
    personal_code: "",
    personal_email: "",
    coursePreferences: [],
    referrer_type: "",
  },
  isCombo: false,
  preferredCalendar: "",
  preferredChildAge: 0,
  ad_name: "",
  bannerActive: true,
  my_promo_code: { coupon_code: "", percentage_discount: "" },
  bannerUpdateDate: 0,
};
const utilityReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_UTM_OBJECT":
      return { ...state, utmObject: { ...action.payload } };
    case "SET_CURRENCY":
      return { ...state, currency: action.payload };
    case "SET_REFERRER_DETAILS":
      return {
        ...state,
        referrerDetails: { ...state.referrerDetails, ...action.payload },
      };
    case "SET_REFERREL_CODE":
      return {
        ...state,
        referrerDetails: { ...state.referrerDetails, ...action.payload },
      };
    case "SET_IS_COMBO":
      return { ...state, isCombo: action.payload };
    case "SET_PREFERRED_CALENDAR":
      return { ...state, preferredCalendar: action.payload };
    case "SET_PREFERRED_CHILD_AGE":
      return { ...state, preferredChildAge: action.payload };
    case "SET_AD_NAME":
      return { ...state, ad_name: action.payload };
    case "SET_BANNER_ACTIVE":
      return { ...state, bannerActive: action.payload };
    case "SET_MY_PROMOCODE_DETAILS":
      return { ...state, my_promo_code: { ...action.payload } };
    case "SET_BANNER_UPDATE_DATE":
      return { ...state, bannerUpdateDate: action.payload };
    default:
      return state;
  }
};
export { utilityReducers };
