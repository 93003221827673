const initState = {
  currentProfile: {
    id: 0,
    name: "",
    first_name: "",
    last_name: "",
    user_id: 0,
    profile_type: "",
    year_of_birth: 0,
    avatar_name: "",
    date_of_birth: "",
    biography: {
      hobbies: "",
      location: "",
      motto: "",
    },
    studio_detail: {
      interests: [],
      friends: [],
      profile_completion_percentage: 10,
      about_me: "",
    },
    uploaded_assignment: false,
    shared: false,
  },
  ProfileSelectionPage: true,
  currentPage: "/home",
  upcomingClasses: [],
  pendingAssignments: [],
  enrolledCourses: [],
  scrollToAssignmentId: "",
  studentProfileCharacteristics: [],
  studioNotification: "",
  homeWorkViewData: [],
  sidebarActive: false,
  calendarEvent: {
    upcomingClassName: "",
    scrollActive: false,
  },
  profileCompletionProgress: 0,
  live_class_card_ab_variant: Math.random(),
  batchAllotmentModal: {
    shouldOpen: true,
    batchData: null,
  },
  gameJson: {},
  game_attempts_id: 0,
  game_status: "NOT_STARTED", // 'STARTED','IN_PROGRESS','END'
  currentProfileStatus: {},
  dashboardAllProfilesClasses: null,
  dashboardAllCompletedClasses: null,
  dashboardAlertStatus: null,
  dashboardAlertData: null,
  dashboardEntryPoint: null,
  dashboardZoomModalsActive: true,
  allClassesRetreivalStatus: 0,
  totalTimeElapsed: 0,
  gamesData: {
    questions: [],
    question: 1,
    game_time: 0,
    total_coins: 0,
    game_coins: 0,
  },
  qualificationModal: {},
  dashboardAllClassesApiLastInvoked: 0,
  isNewSession: true,
  isNextEduUser: false,
  callbackRequested: false,
  showCallbackRequested: false,
};
const dashboardReducersSession = (state = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_PROFILE":
      return {
        ...state,
        currentProfile: { ...state.currentProfile, ...action.payload },
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "SET_NEW_SESSION":
      return {
        ...state,
        isNewSession: action.payload,
      };

    case "SET_UPCOMING_CLASSES":
      return {
        ...state,
        upcomingClasses: action.payload,
      };
    case "SET_PENDING_ASSIGNMENTS":
      return {
        ...state,
        pendingAssignments: action.payload,
      };
    case "SET_ENROLLED_COURSES":
      return {
        ...state,
        enrolledCourses: action.payload,
      };
    case "SET_SCROLL_TO_ASSIGNMENT_ID":
      return {
        ...state,
        scrollToAssignmentId: action.payload,
      };
    case "SET_STUDENT_PROFILE_CHARACTERISTICS":
      return {
        ...state,
        studentProfileCharacteristics: action.payload,
      };
    case "SET_STUDIO_NOTIFICATION":
      return {
        ...state,
        studioNotification: action.payload,
      };
    case "SET_HOMEWORK_VIEW_DATA":
      return {
        ...state,
        homeWorkViewData: action.payload,
      };
    case "SET_SIDEBAR_ACTIVE":
      return {
        ...state,
        sidebarActive: action.payload,
      };
    case "SET_PROFILE_SELECTION_PAGE":
      return {
        ...state,
        ProfileSelectionPage: action.payload,
      };
    case "SET_CALENDAR_SCROLL_EVENT":
      return {
        ...state,
        calendarEvent: action.payload,
      };
    case "SET_PROFILE_COMPLETION_PROGRESS":
      return {
        ...state,
        profileCompletionProgress: action.payload,
      };

    case "SET_GAME_JSON":
      return {
        ...state,
        gameJson: action.payload,
      };

    case "SET_GAME_ATTEMPT_ID":
      return {
        ...state,
        game_attempts_id: action.payload,
      };

    case "SET_BATCH_ALLOTMENT_MODAL":
      return {
        ...state,
        batchAllotmentModal: {
          ...state.batchAllotmentModal,
          ...action.payload,
        },
      };
    case "SET_DASHBOARD_ALL_PROFILES_CLASS_DETAILS":
      return {
        ...state,
        dashboardAllProfilesClasses: action.payload,
      };
    case "SET_DASHBOARD_ALL_COMPLETED_CLASS":
      return {
        ...state,
        dashboardAllCompletedClasses: action.payload,
      };
    case "SET_DASHBOARD_ALERT_STATUS":
      return {
        ...state,
        dashboardAlertStatus: action.payload,
      };
    case "SET_DASHBOARD_ALERT_DATA":
      return {
        ...state,
        dashboardAlertData: action.payload,
      };
    case "SET_DASHBOARD_ENTRY_POINT":
      return {
        ...state,
        dashboardEntryPoint: action.payload,
      };
    case "SET_DASHBOARD_ZOOM_MODALS_ACTIVE":
      return {
        ...state,
        dashboardZoomModalsActive: action.payload,
      };
    case "SET_ALL_CLASSES_RETREIVAL_STATUS":
      return {
        ...state,
        allClassesRetreivalStatus: action.payload,
      };
    case "SET_CURRENT_PROFILE_STATUS":
      return {
        ...state,
        currentProfileStatus: action.payload,
      };
    case "SET_GAME_STATUS":
      return {
        ...state,
        game_status: action.payload,
      };
    case "SET_CURRENT_GAME_TIME":
      return {
        ...state,
        gamesData: { ...state.gamesData, game_time: action.payload },
      };

    case "SET_CURRENT_QUESTION":
      return {
        ...state,
        gamesData: { ...state.gamesData, question: action.payload },
      };

    case "SET_TOTAL_COINS":
      return {
        ...state,
        gamesData: { ...state.gamesData, total_coins: action.payload },
      };
    case "SET_GAME_COINS":
      return {
        ...state,
        gamesData: { ...state.gamesData, game_coins: action.payload },
      };
    case "SET_TOTAL_TIME_ELAPSED":
      return {
        ...state,
        totalTimeElapsed: action.payload,
      };
    case "SET_DASHBOARD_ALL_CLASSES_API_LAST_INVOKED":
      return {
        ...state,
        dashboardAllClassesApiLastInvoked: action.payload,
      };
    case "SET_NEXT_EDU_USER":
      return { ...state, isNextEduUser: action.payload };
    case "SET_CALLBACK_REQUESTED":
      return {
        ...state,
        callbackRequested: action.payload,
      };
    case "SET_SHOW_CALLBACK_REQUESTED":
      return {
        ...state,
        showCallbackRequested: action.payload,
      };
    case "SET_SHOW_QUALIFICATION_MODAL":
      return {
        ...state,
        qualificationModal: {
          ...state.qualificationModal,
          [action.payload]: true,
        },
      };
    default:
      return state;
  }
};
export { dashboardReducersSession };
