const initState = {
  context: "trial",
  progress: "0%",
  currentStage: 1,
  stageOneLiner: "First 3 trials are free!",
  nextPossibleStage: 1,
  clickedStageThreeTab: false,
  courseOutlineId: "",
  variantNumber: 0,
  preferredCourseId: "",
  isBookingCompleted: false,
  stageOneDetails: {
    parent_name: "",
    phone_number: "",
    email: "",
    child_name: "",
    old_child_name: "",
    child_age: 0,
    old_child_age: 0,
    otp_sent_to: "",
    is_verified: false,
    is_reschedule: false,
    reschedule_uuid: "",
    short_lived_token: "",
    short_lived_user_id: "",
    current_profile_id: 0,
  },
  stageTwoDetails: {
    available_courses: [],
    new_available_slots: [],
    initial_render: true,
    selected_slot_ids: [],
    selected_slots_course_id: [],
    booked_slot_details: [],
    all_custom: false,
    selected_courses: [],
    selected_course_count: [],
  },
  stageThreeDetails: {
    available_slots: [],
    parsed_slots: [],
    selected_day: "Recommended",
    selected_slots: [],
    selected_referrer_slots: [],
    selected_slots_course_id: [],
    selected_slots_with_timing: [],
    initial_render: true,
    all_custom: false,
  },
  stageFourDetails: { selected_slots: [] },
  personalisationDetails: {
    coursesInterestedIn: [],
    courseIdsInterestedIn: [],
    skillsInterestedIn: [],
  },
  continuedOnboarding: { show_add_to_calendar_tooltip: true },
  fetchReferrerDetails: false,
  students_in_booked_batch: [],
  PERCENTAGE_LEFT: {
    INITIAL_LOAD: 0,
    JOIN_ON_LAPTOP: 25,
    ZOOM_ON_LAPTOP: 50,
    CHECK_YOUR_MAIL: 75,
    HOW_FUN_CLASS: 100,
    ZOOM_ON_MOBILE: 50,
    INSTALL_ZOOM_MOBILE: 75,
    CLASS_STARTS_IN: 100,
    CLASS_HAS_STARTED: 100,
    TROUBLE_JOINING_CLASS: 100,
  },
  courses_unbooked: null,
};
const bookingReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_CONTEXT":
      return { ...state, context: action.payload };
    case "SET_VARINANT_NUMBER":
      return { ...state, variantNumber: action.payload };
    case "SET_PROGRESS":
      return { ...state, progress: action.payload };
    case "SET_NEXT_POSSIBLE_STAGE":
      return { ...state, nextPossibleStage: action.payload };
    case "SET_CLICKED_STAGE_THREE_TAB":
      return { ...state, clickedStageThreeTab: action.payload };
    case "SET_STAGE_ONE_LINER":
      return { ...state, stageOneLiner: action.payload };
    case "SET_COURSE_OUTLINE_ID":
      return { ...state, courseOutlineId: action.payload };
    case "SET_PREFERRED_COURSE_ID":
      return { ...state, preferredCourseId: action.payload };
    case "SET_FETCH_REFERRER_DETAILS":
      return { ...state, fetchReferrerDetails: action.payload };

    case "SET_IS_RESCHEDULE":
      return {
        ...state,
        stageOneDetails: {
          ...state.stageOneDetails,
          is_reschedule: action.payload,
        },
      };
    case "SET_CURRENT_STAGE":
      return { ...state, currentStage: action.payload };

    case "SET_STAGE_ONE_DETAILS":
      return {
        ...state,
        stageOneDetails: { ...state.stageOneDetails, ...action.payload },
      };
    case "SET_STAGE_TWO_DETAILS":
      return {
        ...state,
        stageTwoDetails: { ...state.stageTwoDetails, ...action.payload },
      };
    case "SET_STAGE_THREE_DETAILS":
      return {
        ...state,
        stageThreeDetails: { ...state.stageThreeDetails, ...action.payload },
      };
    case "SET_STAGE_FOUR_DETAILS":
      return {
        ...state,
        stageFourDetails: { ...state.stageFourDetails, ...action.payload },
      };
    case "SET_PERSONALISATION_DETAILS":
      return {
        ...state,
        personalisationDetails: {
          ...state.personalisationDetails,
          ...action.payload,
        },
      };
    case "SET_STUDENTS_IN_BOOKED_BATCH":
      return {
        ...state,
        students_in_booked_batch: action.payload,
      };
    case "SET_COURSES_UNBOOKED":
      return { ...state, courses_unbooked: action.payload };
    case "SET_IS_BOOKING_COMPLETED":
      return {
        ...state,
        isBookingCompleted: action.payload,
      };
    case "RESET_DETAILS_AFTER_BOOKING":
      return { ...initState };

    default:
      return state;
  }
};
export { bookingReducers };
