const initState = {
  userDetails: {},
  currentProfile: {},
  dashboard_ftue_current_stage: null,
};
const dashboardReducersLocal = (state = initState, action) => {
  switch (action.type) {
    case "SET_DASHBOARD_FTUE_CURRENT_STAGE":
      return {
        ...state,
        dashboard_ftue_current_stage: action.payload,
      };
    default:
      return state;
  }
};
export { dashboardReducersLocal };
