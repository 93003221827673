const initState = {
  currentStage: 1,
  badges: [],
};
const studioReducers = (state = initState, action) => {
  switch (action.type) {
    case "SET_BATCH_STAGE":
      return { ...state, currentStage: action.payload };

    case "SET_BADGE":
      return { ...state, badges: [...state.badges, action.payload] };

    default:
      return state;
  }
};
export { studioReducers };
