import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
// ! individual reducers
import { checkoutReducers } from "./checkoutReducers";
import { authReducers } from "./authReducers";
import { coursesReducers } from "./coursesReducers";
import { utilityReducers } from "./utilityReducers";
import { recordingReducers } from "./recordingReducer";
import { bookingReducers } from "./bookingReducers";
import { bookingSessionReducers } from "./bookingSessionReducers";
import { dashboardReducersLocal } from "./dashboardReducersLocal";
import { dashboardReducersSession } from "./dashboardReducersSession";
import { liveClassReducers } from "./liveClassReducers";
import { gamesReducer } from "./gamesReducer";
import { teacherDashboardReducersSession } from "./teacherDashboardReducersSession";
import { studioReducers } from "./studioReducers";
// ! For storing courses on session storage
const coursesPersistConfig = {
  key: "courses",
  storage: storageSession,
};
const recordingPersistConfig = {
  key: "recording",
  storage: storageSession,
};
const dashboardSessionPersistConfig = {
  key: "dashboard_session",
  storage: storageSession,
};
const gamesPersistConfig = {
  key: "games",
  storage: storageSession,
};
// ! For storing booking on session storage

const bookingPersistConfig = {
  key: "booking",
  storage: storageSession,
};

const bookingSessionPersistConfig = {
  key: "booking_session",
  storage: storageSession,
};

const liveClassPersistConfig = {
  key: "liveclass",
  storage: storageSession,
};

const teacherDashboardSessionPersistConfig = {
  key: "teacher_dashboard_session",
  storage: storageSession,
};

const rootReducer = combineReducers({
  // ! Local storage
  checkout: checkoutReducers,
  auth: authReducers,
  utility: utilityReducers,
  dashboard_local: dashboardReducersLocal,
  booking: bookingReducers,
  studio:studioReducers,
  // ! Session storage

  courses: persistReducer(coursesPersistConfig, coursesReducers),
  games: persistReducer(gamesPersistConfig, gamesReducer),
  recording: persistReducer(recordingPersistConfig, recordingReducers),
  // booking: persistReducer(bookingPersistConfig, bookingReducers),
  dashboard_session: persistReducer(
    dashboardSessionPersistConfig,
    dashboardReducersSession
  ),
  live_class: persistReducer(liveClassPersistConfig, liveClassReducers),
  booking_session: persistReducer(
    bookingSessionPersistConfig,
    bookingSessionReducers
  ),
  teacher_dashboard_session: persistReducer(
    teacherDashboardSessionPersistConfig,
    teacherDashboardReducersSession
  ),
});

export default rootReducer;
