const inititalState = {
  completedClassData: {
    timeToRefreshCompletedClassData: 0,
    classesData: [],
    currentPage: 0,
    totalPages: 0,
    currentFetchedData: [],
  },

  activitiesData: {
    timeToRefreshActivitiesData: 0,
    activities: [],
  },
};

const teacherDashboardReducersSession = (state = inititalState, action) => {
  switch (action.type) {
    case "SET_COMPLETED_CLASS_CACHE_DATA":
      return {
        ...state,
        completedClassData: {
          timeToRefreshCompletedClassData: action.payload.time,
          classesData: action.payload.data,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          currentFetchedData: action.payload.currentFetchedData,
        },
      };
    case "SET_ACTIVITIES_CACHE_DATA":
      return {
        ...state,
        timeToRefreshActivitiesData: action.payload,
      };

    default:
      return state;
  }
};
export { teacherDashboardReducersSession };
