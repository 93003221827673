import React, { useEffect } from "react";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";
// ! GLOBAL STYLING IMPORTS
import "../styles/app.scss";
import TagManager from "react-gtm-module";
// import mixpanel from "mixpanel-browser";
import { useStore } from "../store/store";
import { Provider, useDispatch } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { gtm, env } from "../helpers/environmentHelpers";
//! Posthog
import posthog from "posthog-js";
// ! React alert imports
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
// import { hotjar } from "react-hotjar";
//! SEO IMPORTS

import { LogoJsonLd, CorporateContactJsonLd } from "next-seo";
// import BlogOgTags from "../components/SeoComponents/BlogOgTags";
// ! mixpanel initialization
// mixpanel.init("d4a75bc05ba5b818d6719609241d6ab9", {
//   debug: mixPanel() == 1 ? true : false,
//   ip: true,
//   ignore_dnt: true,
//   batch_flush_interval_ms: 10000,
// });
// export function reportWebVitals(metric) {
//   console.log(metric);
// }
// ! root component
function MyApp({ Component, pageProps, res }) {
  const store = useStore(pageProps.initialReduxState);
  const persistor = persistStore(store, {}, function () {
    persistor.persist();
  });
  // useEffect(() => {
  //   hotjar.initialize(2626680, 7);
  // }, []);
  const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: "6px",
    transition: transitions.FADE,
    // containerStyle: { width: "350px", padding: "15px", zIndex: 100 },
    className: "alert-container",
  };

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      autocapture: false,
      capture_pageleave: false,
      capture_pageview: false,
    });
    window.__posthog = posthog;
  }, []);
  useEffect(() => {
    TagManager.initialize({ gtmId: gtm() });
    // window.mixpanel = mixpanel;
  }, []);
  const { openGraphData = [] } = pageProps;
  return (
    <>
      {/* <DefaultSeo {...SEO} /> */}
      <Head>
        <html lang="en" />
        <title>Spark Studio | Online Learning for Kids</title>
        <meta
          name="description"
          content="Live Online Classes in Communication, Music & Art"
        />
        {openGraphData.map((og) => (
          <meta {...og} />
        ))}
      </Head>
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="shortcut icon"
          href="https://sparkstudiowebassets.blob.core.windows.net/assets-sparkstudio-co/staging/favicon.ico"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&display=swap"
          rel="stylesheet"
        />
        {/* <BlogOgTags /> */}
      </Head>
      <LogoJsonLd
        logo="https://sparkstudiowebassets.blob.core.windows.net/assets-sparkstudio-co/staging/sparkLogo.png"
        url="https://sparkstudio.co/"
      />
      <CorporateContactJsonLd
        url="https://sparkstudio.co/"
        logo="https://sparkstudiowebassets.blob.core.windows.net/assets-sparkstudio-co/staging/sparkLogo.png"
        contactPoint={[
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "India",
            availableLanguage: ["English", "Hindi", "Marathi"],
          },
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "UAE",
            availableLanguage: ["English"],
          },
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "SIN",
            availableLanguage: ["English"],
          },
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "Oman",
            availableLanguage: ["English"],
          },
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "Qatar",
            availableLanguage: ["English"],
          },
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "Saudi Arabia",
            availableLanguage: ["English"],
          },
          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "Yemen",
            availableLanguage: ["English"],
          },

          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "Bahrain",
            availableLanguage: ["English"],
          },

          {
            telephone: "+91-63637-01578",
            contactType: "customer service",
            areaServed: "Kuwait",
            availableLanguage: ["English"],
          },
        ]}
      />
      <Provider store={store}>
        <PersistGate
          loading={
            <div
              style={{
                background: `linear-gradient(180deg,rgba(255, 130, 205, 0.2) -59.43%,rgba(255, 253, 201, 0.4) 100%)`,
              }}
            ></div>
          }
          persistor={persistor}
        >
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Component {...pageProps} />
          </AlertProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default MyApp;
